import React from "react";
import { useState } from "react";
import { Button, Input, Logo, Typography } from "../../../components";
import { api } from "../../../services/base.service";
import { setItem } from "../../../services/storage.service";

import * as S from "./ResetPasswordStyled";

function ResetPassword(props) {
  const [errorAuth, setErrorAuth] = useState([]);
  const [passwordSent, setPasswordSent] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props.match.params);
  const handleSubmit = (data) => {
    setErrorAuth([]);
    setLoader(true);
    api
      .post("identidade/alterar-senha", {
        senha: data.password,
        senhaConfirmacao: data.confirmPassword,
        email: props.match.params.email,
        token: props.match.params.token,
      })
      .then(
        (res) => {
          setLoader(false);
          setPasswordSent(true);
          console.log(res);
        },
        (error) => {
          setLoader(false);
          setErrorAuth(error.response.data.errors);
        }
      )
      .catch((error) => console.log(error.response));
  };
  return (
    <S.Container>
      <S.Box>
        {!passwordSent ? (
          <>
            <Logo />
            <Typography marginBottom="20px" marginTop="20px" variant="h1">
              Alterar senha
            </Typography>
            <S.Card>
              <S.FormContainer onSubmit={handleSubmit}>
                <Input
                  label="Nova senha"
                  name="password"
                  type="text"
                  placeholder={""}
                />

                <Input
                  label="Repetir nova senha"
                  name="confirmPassword"
                  type="password"
                  placeholder={""}
                />
                {/* </S.InputPassword> */}
                {errorAuth.length !== 0 && (
                  <S.ErrosAuth>
                    {errorAuth.map((error) => {
                      return <S.TextError>- {error}</S.TextError>;
                    })}
                  </S.ErrosAuth>
                )}

                <Button loader={loader}>Definir nova senha</Button>
              </S.FormContainer>
            </S.Card>
          </>
        ) : (
          <>
            <S.SentEmail>
              <S.IconSentEmail />
              <Typography variant="h2">Senha alterada com sucesso!</Typography>
              <S.Spacer></S.Spacer>
            </S.SentEmail>
            <Button marginTop={10} width={"400px"}>
              <a href="/"> Voltar para login </a>
            </Button>
          </>
        )}
      </S.Box>
    </S.Container>
  );
}

export default ResetPassword;
