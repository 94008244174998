import styled from "styled-components";
import { Form } from "@unform/web";

const Container = styled.div`
  //   display: flex;
  //   justify-content: flex-end;
  padding: 10px;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  //   /* width: 400px; */
  //   max-height: 310px;
  //   justify-content: space-around;
`;

const BoxText = styled.div`
  margin-bottom: 25px;
`;

const InputPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const MensageError = styled.span`
  color: red;
  margin-bottom: 15px;
`;
export { Container, FormContainer, BoxText, InputPassword, MensageError };
