import { Form } from "@unform/web";
import { MdCheck } from "react-icons/md";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Box = styled.div`
  text-align: center;
  @media (max-width: 720px) {
    width: 100%;
    margin: 0 11px;
  }
`;
const Card = styled.div``;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 310px;
  justify-content: space-around;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const InputPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ErrosAuth = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 10px 0;
`;
const TextError = styled.span`
  color: red;
  margin: 3px 0;
`;

const SentEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 400px;
`;
const IconSentEmail = styled(MdCheck)`
  font-size: 4em;
  color: green;
`;
const Spacer = styled.div`
  height: 20px;
`;

export {
  Container,
  Box,
  Card,
  FormContainer,
  InputPassword,
  ErrosAuth,
  TextError,
  IconSentEmail,
  SentEmail,
  Spacer,
};
