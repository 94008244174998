import styled from "styled-components";

const Container = styled.div``;

const Button = styled.button`
  border: none;
  width: 100%;
  height: 40px;
  background-color: #2D346A;
  cursor: pointer;
`;
const TextButton = styled.span`
  border: none;
  width: 100%;
  height: 40px;
  color: #fff;
`;
export { Container, Button, TextButton };
