import React from "react";
import Loader from "react-loader-spinner";

import * as S from "./ButtonStyled";

const Button = ({ children, loader }) => {
  return (
    <S.Container>
      <S.Button>
        {loader ? (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={20}
            width={20} //3 secs
          />
        ) : (
          <S.TextButton>{children}</S.TextButton>
        )}
      </S.Button>
    </S.Container>
  );
};

export default Button;
