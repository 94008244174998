import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_IDENTIDADE,
  auth: false,
});

const apiUser = axios.create({
  baseURL: process.env.REACT_APP_API_HUB_V2,
  auth: false,
});

export { api, apiUser };
